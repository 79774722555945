<template>
  <div class="account-center">
    <div class="cell cell-head">
      <div class="cell-head-img">
        <div class="cell-head-avatar">
          <img src="../../assets/images/student.png" alt="">
        </div>
        <div class="cell-head-name">{{ nickName || '---' }}</div>
        <!-- <div class="cell-head-phone">
          <icon-phone />
          {{ phone || '暂无' }}
        </div> -->
      </div>
      <div class="cell-head-container">
        <div class="cell-head-title">累计学习 <span style="color: #4F17A8; font-size: 30px;">{{ studyTotalDuration || '0' }}</span> 分钟</div>
        <div class="cell-head-list">
          <div class="cell-head-item">
            <div class="cell-head-item-statistics">
              <span style="color: #323233; font-weight: 700; font-size: 30px;">{{ todayStudyTotalDuration || '0' }}</span> 分钟
            </div>
            <div class="cell-head-item-text">今日学习</div>
          </div>
          <!-- <div class="cell-head-item cell-head-items">
            <div class="cell-head-item-statistics">
              <span style="color: #323233; font-weight: 700; font-size: 30px;">{{ studyDays || '0' }}</span> 天
            </div>
            <div class="cell-head-item-text">累积学习</div>
          </div> -->
          <div class="cell-head-item cell-head-items">
            <div class="cell-head-item-statistics">
              <span style="color: #323233; font-weight: 700; font-size: 30px;">{{ studyCourses || '暂无' }}</span> 门
            </div>
            <div class="cell-head-item-text" style="text-align: right;">累计学习课程</div>
          </div>
        </div>
      </div>
    </div>
    <div class="account-center-title">
      <span class="title">学习进度</span>
      <span class="cursor" @click="$router.push({path:'/account/course'})">查看更多 ></span>
    </div>
    <div class="cell cell-progress">
      <div class="cell-progress-list" v-if="progressList && progressList.length > 0">
        <div class="cell-progress-item" v-for="(item, index) in progressList" :key="index" >
          <a-tooltip :content="item.courseName">
            <div class="cell-progress-name line-text-1">
              <icon-bookmark />
              {{item.courseName}}
            </div>
          </a-tooltip>
          <div class="cell-progress-c" style="flex: 1;">
            <a-progress :percent="item.process/100" :stroke-width="6" color="#4F17A8" track-color="#f5f5f5">
              <template v-slot:text="scope" >
                <div style="width: 80px; text-align: left; color: #4F17A8; font-size: 14px;">已学习 {{ parseInt((scope.percent == null ? 0: scope.percent)*100  + '')}}%</div>
              </template>
            </a-progress>
          </div>
          <div class="cell-progress-button">
            <div v-if="item.status == 1 && item.studyState == 1" class="btn btn1" @click="handClick(item)">继续学习</div>
            <!-- <div class="btn btn2" style="margin-left: 10px;">申请结业</div> -->
          </div>
        </div>
      </div>
      <a-empty v-else>暂无记录</a-empty>
    </div>
    
    <!-- <div class="account-center-title" >
      <span class="title">直播课程</span>
    </div>
    <div class="cell cell-live">
      <div class="cell-live-carousel" v-if="footprintList.length > 0">
        <div class="arrow left" ><icon-left /></div>
        <div class="arrow right" ><icon-right /></div>
        <a-carousel auto-play ref="carousel" :style="{ width: '100%', height: '116px', }" indicator-type="never" show-arrow="never">
          <a-carousel-item v-for="(item, index) in footprintList" :key="index">
            <img :src="item" :style="{ width: '100%', }" />
          </a-carousel-item>
        </a-carousel>
      </div>
      <a-empty v-else>暂无记录</a-empty>
    </div> -->
    <!-- <div class="account-center-title">
      <span class="title">我的足迹</span>
      <span class="cursor">查看更多 ></span>
    </div>
    <div class="cell cell-footprint">
      <div class="cell-footprint-list" v-if="footprintList.length > 0">
        <div class="cell-footprint-item" v-for="(item, index) in footprintList" :key="index">
          <div class="img">
            <img style="width: 100%; height: 120px; border-radius: 4px 4px 0 0;" :src="item" alt="">
          </div>
          <div class="cell-footprint-container">
            <div class="cell-footprint-name line-text-1">工程造价改革与管理工程造价改革与管理工程造价改革与管理</div>
            <div class="cell-footprint-time">
              <icon-calendar-clock />
              已看00:23
            </div>
          </div>
        </div>
      </div>
      <a-empty v-else>暂无记录</a-empty>
    </div> -->

  </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import { userInfo,ratePage }  from '@/api/course';
import { regExpLiteral } from '@babel/types';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const state = reactive({
      nickName: '', //	昵称	string	
      phone: '', //	手机号	string	
      studyCourses: '', //	累计学习课程	integer(int32)	
      studyDays: '', //	累计学习天	integer(int32)	
      studyTotalDuration: '', //	累计学习时长	integer(int64)	
      todayStudyTotalDuration: '', //	今日学习时长
      progressList: [
        // { name: '工程造价改革与管理工程造价改革与管理', num: 20, status: 1 },
        // { name: '工程造价改革与管理管理', num: 30, status: 1 },
        // { name: '工程造价改革与管理', num: 50, status: 1 },
        // { name: '工程造价改革与管理管理管理', num: 80, status: 1 },
        // { name: '工程造价改革与管理管理管理管理管理', num: 100, status: 2 }
      ],
      // footprintList: [
      //   'http://static.roncoos.com/os/11.jpg',
      //   'http://static.roncoos.com/os/12.jpg',
      //   'http://static.roncoos.com/os/12.jpg',
      //   'http://static.roncoos.com/os/12.jpg',
      //   'http://static.roncoos.com/os/12.jpg',
      //   'https://static-dev.roncoo.com/course/587ead2ffb694d1ca1d28e262b54accb.png'
      // ]
    });
    const getData = () => {
      userInfo().then(res => {
        if (res.code === 20000) {
          const data = res.result;
          state.nickName = data.nickName;
          state.phone = data.phone;
          state.studyCourses = data.studyCourses;
          state.studyDays = data.studyDays;
          state.studyTotalDuration = data.studyTotalDuration;
          state.todayStudyTotalDuration = data.todayStudyTotalDuration;
        }
      })
    };
    getData();
    const getList = (current, size) => {
      ratePage({current, size}).then(res => {
        state.progressList = res.result.records;
      })
    };
    getList(1,10);
    const router = useRouter ()
    const handClick = (v) => {
        router.push({ 
            path: '/account/courseware',
            query:{
                courseId:v.courseId,
                classId:v.classId,
            }
        })
        sessionStorage.setItem('courseInfo',JSON.stringify(v))
    };
    return {
      ...toRefs(state),
      handClick
    }
  }
}
</script>
<style lang="less" scoped>
.account-center {
  .account-center-title {
    display: flex;
    height: 30px;
    align-items: center;
    margin: 15px 0;
    color: #B7B7B7;
    font-size: 14px;
    .title {
      flex: 1;
      color: #323233;
      font-size: 20px;
    }
  }
  .cell {
    width: 100%;
    background: #fff;
    border-radius: 4px;
  }
  .cell-head {
    height: 180px;
    display: flex;
    align-items: center;
    .cell-head-img {
      width: 220px;
      display: flex;
      align-items: center;
      flex-flow: column;
      margin-right: 30px;
      .cell-head-avatar {
        width: 82px;
        height: 82px;
        border-radius: 50%;
        img {
          display: block;
          width: 82px;
          height: 82px;
          border-radius: 50%;
        }
      }
      .cell-head-name {
        margin: 10px 0;
        font-weight: 700;
        font-size: 18px;
        color: #333;
      }
      .cell-head-phone {
        color: #666;
        font-size: 14px;
      }
    }
    .cell-head-container {
      flex: 1;
      .cell-head-title {
        font-size: 18px;
        font-weight: 700;
        color: #333;
        margin-bottom: 26px;
      }
      .cell-head-list {
        display: flex;
        // justify-content: center;
        .cell-head-item:nth-child(1) {
          flex: none;
          width: 160px;
        }
        .cell-head-items {
          align-items: center;
          border-left: 1px solid #EAEAEA;
          box-sizing: border-box;
        }
        .cell-head-item {
          // flex: 1;
          width: 160px;
          display: flex;
          flex-flow: column;
          .cell-head-item-text {
            margin-top: 10px;
            color: #7D7D7D;
            font-size: 14px;
          }
        }
      }
    }
  }
  .cell-progress {
    padding: 16px 28px 16px 24px;
    box-sizing: border-box;
    .cell-progress-item + .cell-progress-item {
      margin-top: 15px;
    }
    .cell-progress-item {
      display: flex;
      align-items: center;
      .cell-progress-name {
        width: 230px;
        color: #333;
        font-size: 14px;
        margin-right: 20px;
      }
      .cell-progress-button {
        display: flex;
        margin-left: 30px;
        height: 33px;
        width: 90px;
      }
    }
  }
  // 直播
  .cell-live {
    padding: 35px 0;
    box-sizing: border-box;
    .cell-live-carousel {
      padding: 0 40px;
      box-sizing: border-box;
      position: relative;
      .arrow {
        position: absolute;
        top: 38px;
        width: 24px;
        height: 40px;
        background: #D0D0D0;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        color: #fff;
        cursor: pointer;
      }
      .left {
        left: 6px;
      }
      .right {
        right: 6px;
      }
    }
  }
  // 足迹
  .cell-footprint {
    padding: 24px;
    box-sizing: border-box;
    .cell-footprint-list {
      display: flex;
      flex-wrap: wrap;
      .cell-footprint-item:not(:nth-child(4n)) {
        margin-right: calc(4% / 3);
      }
      .cell-footprint-item {
        cursor: pointer;
        margin-bottom: calc(4% / 3);
        width: 24%;
        // height: 180px;
        background: #FFFFFF;
        box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.1000);
        border-radius: 4px;
        .cell-footprint-container {
          height: 60px;
          display: flex;
          flex-flow: column;
          justify-content: center;
          padding: 0 10px;
          box-sizing: border-box;
          .cell-footprint-name {
            font-size: 14px;
            color: #333;
          }
          .cell-footprint-time {
            margin-top: 6px;
            font-size: 12px;
            color: #7B7B7B;
          }
        }
      }
    }
  }
}
.btn {
  cursor: pointer;
  width: 88px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  color: #FFFFFF;
  font-size: 14px;
}
.btn1 {
  background: #4F17A8;
}
.btn2 {
  background: #D48806;
}
</style>
